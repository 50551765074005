.mourn-ribbon {
  width: 80px;
  height: 124px;
  z-index: 1000; }
  .mourn-ribbon .ribbon-top {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 30px;
    height: 14px;
    background: #000;
    border: 2px solid #FFF; }
  .mourn-ribbon .ribbon-wing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 110px;
    margin-left: 1px; }
    .mourn-ribbon .ribbon-wing.ribbon-left {
      margin-top: -1px;
      transform: translate(-50%, -50%) rotate(-24deg); }
      .mourn-ribbon .ribbon-wing.ribbon-left .ribbon-outside .ribbon-content,
      .mourn-ribbon .ribbon-wing.ribbon-left .ribbon-inside .ribbon-content {
        border-top-left-radius: 20px; }
    .mourn-ribbon .ribbon-wing.ribbon-right {
      transform: translate(-50%, -50%) rotate(24deg); }
      .mourn-ribbon .ribbon-wing.ribbon-right .ribbon-outside .ribbon-content,
      .mourn-ribbon .ribbon-wing.ribbon-right .ribbon-inside .ribbon-content {
        border-top-right-radius: 20px; }
      .mourn-ribbon .ribbon-wing.ribbon-right .ribbon-outside {
        height: 112px; }
        .mourn-ribbon .ribbon-wing.ribbon-right .ribbon-outside .ribbon-content {
          height: 92px; }
      .mourn-ribbon .ribbon-wing.ribbon-right .ribbon-inside {
        top: 4px; }
    .mourn-ribbon .ribbon-wing > div {
      position: absolute;
      font: normal 100%/normal Arial, Helvetica, sans-serif;
      color: black;
      text-overflow: clip; }
    .mourn-ribbon .ribbon-wing .ribbon-outside {
      top: 0;
      left: 0;
      height: 111px; }
      .mourn-ribbon .ribbon-wing .ribbon-outside .ribbon-content {
        height: 91px;
        background: #FFF; }
      .mourn-ribbon .ribbon-wing .ribbon-outside .ribbon-bottom {
        border: 8px solid #FFF;
        border-top: 0 solid;
        border-bottom: 10px solid transparent; }
    .mourn-ribbon .ribbon-wing .ribbon-inside {
      top: 3px;
      left: 2px;
      height: 104px; }
      .mourn-ribbon .ribbon-wing .ribbon-inside .ribbon-content {
        height: 83px;
        background: #000; }
      .mourn-ribbon .ribbon-wing .ribbon-inside .ribbon-bottom {
        border: 6px solid #000;
        border-top: 0 solid;
        border-bottom: 10px solid transparent; }
  .mourn-ribbon.small {
    width: 60px;
    height: 70px; }
    .mourn-ribbon.small .ribbon-top {
      top: 9px;
      width: 16px;
      height: 9px;
      border: 1px solid #FFF; }
    .mourn-ribbon.small .ribbon-wing {
      margin-left: -1px;
      width: 10px;
      height: 55px; }
      .mourn-ribbon.small .ribbon-wing.ribbon-left {
        margin-top: 2px;
        transform: translate(-50%, -50%) rotate(-30deg); }
      .mourn-ribbon.small .ribbon-wing.ribbon-right {
        margin-top: -1px;
        transform: translate(-50%, -50%) rotate(30deg); }
        .mourn-ribbon.small .ribbon-wing.ribbon-right .ribbon-inside {
          top: 4px; }
      .mourn-ribbon.small .ribbon-wing .ribbon-outside {
        top: 0;
        left: 0; }
        .mourn-ribbon.small .ribbon-wing .ribbon-outside .ribbon-content {
          height: 50px;
          background: #FFF; }
        .mourn-ribbon.small .ribbon-wing .ribbon-outside .ribbon-bottom {
          border: 7px solid #FFF;
          border-top: 0 solid;
          border-bottom: 5px solid transparent; }
      .mourn-ribbon.small .ribbon-wing .ribbon-inside {
        top: 3px;
        left: 2px;
        height: 50px; }
        .mourn-ribbon.small .ribbon-wing .ribbon-inside .ribbon-content {
          height: 42px;
          background: #000; }
        .mourn-ribbon.small .ribbon-wing .ribbon-inside .ribbon-bottom {
          border: 5px solid #000;
          border-top: 0 solid;
          border-bottom: 5px solid transparent; }

.switch-container {
  position: fixed;
  top: 200px;
  right: 0;
  padding-top: 27px;
  padding-left: 0px;
  width: 70px;
  height: 70px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
  z-index: 1000;
  /* The switch - the box around the slider */ }
  .switch-container .title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-15px, -50%) rotate(-90deg);
    font-size: 11px;
    text-transform: uppercase;
    color: #999; }
  .switch-container .switch {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-2px, -50%);
    display: inline-block;
    width: 50px;
    height: 28px;
    /* Hide default HTML checkbox */
    /* The slider */ }
    .switch-container .switch input {
      display: none; }
    .switch-container .switch .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f45c42;
      transition: .4s;
      transform: rotate(90deg);
      /* Rounded sliders */ }
      .switch-container .switch .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transform: translateX(0);
        transition: .4s; }
      .switch-container .switch .slider.round {
        border-radius: 34px; }
        .switch-container .switch .slider.round:before {
          border-radius: 50%; }
    .switch-container .switch input:checked + .slider {
      background-color: #666; }
    .switch-container .switch input:focus + .slider {
      box-shadow: 0 0 1px #7f8c8d; }
    .switch-container .switch input:checked + .slider:before {
      transform: translateX(22px); }
